<script>
	import SuperInput from '$lib/Components/Forms/SuperInput/SuperInput.svelte';

	import countries from './countryList.json';

	let {
		form,
		style = '',
		error = '',
		name = 'country',
		label = 'Country',
		autocomplete = 'country-name',
		disabled = null,
		class: className = '',
	} = $props();
</script>

<SuperInput
	{form}
	class={[className ?? ''].join(' ')}
	options={countries}
	valueField="name"
	labelField="name"
	type="select"
	{name}
	{label}
	{style}
	{error}
	{autocomplete}
	{disabled}
/>
